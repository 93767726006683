/* Общие стили для таблицы */


.table-wrapper {
    overflow-x: auto;
    margin-right: 2.5rem;
}

.table-wrapper .styled-table {
    width: 100%;
}

@media (max-width: 600px) {
    .table-wrapper {
        overflow-x: auto;
        margin-right: 0rem;
    }

    .styled-table {
        display: block;
        min-width: 600px; 
    }
}

.styled-table {
    border-collapse: collapse;
    border: none;
    width: 50%;
    border-radius: 40px;
    background-color: rgba(173, 216, 230, 0.7);
    font-weight: 400;
  
}

/* Стили для ячеек таблицы */
.styled-table td,
.styled-table th {
    border: none;
    padding: 8px;
    color: black;
}

/* Стили для заголовков */
.styled-table th {
    background-color: #f2f2f2;
}

/* Стили для ячеек первого столбца */
.styled-table td:first-child {
    text-align: center;
}

/* Стили для ячеек второго столбца */
.styled-table td:nth-child(2) {
    text-align: left;
}

/* Стили для ячеек третьего и четвертого столбцов */
.styled-table td:nth-child(3),
.styled-table td:nth-child(4) {
    text-align: center;
}

/* Дополнительные стили для ячеек с параграфами */
.styled-table td p {
    margin: 0;
}

/* Стили для ячеек с многострочным текстом */
.styled-table td p:not(:last-child) {
    margin-bottom: 5px;
}

/* Правая граница столбцов в tbody */
.styled-table tbody td:not(:last-child) {
    border-right: 1px solid #f2f2f286;
}

/* Нижняя граница для заголовков */
.styled-table th,
.styled-table tr:first-child {
    border-bottom: 1px solid #f2f2f286;
}

/* Внутренний отступ для содержимого первого tr */
.styled-table tr:first-of-type td {
    padding-top: 30px;
}