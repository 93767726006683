:root {
	/*--perspective: 1px;*/
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100vh;
	background: #3d87d3;
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}


a {
	color: #4d8ad0;
	text-decoration: none;
	font-weight: 600;
}

.blue a {
	color: #fff;
}

a:hover {
	color: lightblue;
}

.main {
	position: relative;
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	overflow: hidden;
}


.main>nav {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 8em;
	z-index: 990;
}

.main>nav ul {
	list-style: none;
	display: flex;
	float: right;
	margin-right: 5em;
	transition: all 0.5s ease-out;
}

.main>nav ul .hambugger {
	display: none;
}

.main>nav ul li {
	display: block;
	margin: 2em 2em 2em;
	color: white;
}

.main>nav .header {
	margin: 0 2em;
}

.main section {
	position: relative;
	z-index: 10;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 2rem;
	height: inherit;
	min-height: inherit;
	position: relative;
}

.container.article-container {
	max-width: 1200px;
	margin: 0 auto;
}

/* .container>* {
	margin: 0 2vw;
} */

.news-container {
	max-width: 1600px;
	height: inherit;
	min-height: inherit;
	position: relative;
}

.service-container {
	max-width: 1600px;
	margin: 0 auto;
	height: inherit;
	min-height: inherit;
	position: relative;
}

.white-circle:before {
	content: " ";
	position: absolute;
	width: 6em;
	height: 6em;
	bottom: -3em;
	left: calc(50% - 3em);
	background: inherit;
	border-radius: 100%;
}

/* paralllax */

.prlx {
	height: 100vh;
	perspective: 1px;
	overflow: hidden;
	overflow-y: auto;
}

.prlx-l {
	position: absolute;
	inset: 0;
}

.prlx-l-1 {
	transform: translateZ(-3px) scale(4.1);
}

.prlx-l-2 {
	transform: translateZ(-4px) scale(6.2);
}

.prlx-l-3 {
	transform: translateZ(-6px) scale(7);
}

.prlx-l-4 {
	transform: translateZ(-10px) scale(11.8);
}


.bg-img {
	height: 190vh;
	background: linear-gradient(11deg, rgba(61, 135, 211, 1) 0%, rgba(38, 187, 212, 1) 77%, rgba(0, 91, 146, 1) 88%);
	/* top: 400% */
}

.bg-clouds {
	background-image: url(../../Assets/img/bg-clouds.png);
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
	opacity: 0.4;
	transition: all 0.5s ease-out;
}

.bg-stars {
	background-image: url(../../Assets/img/bg-stars1.png);
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
	opacity: 0.6;
	transition: all 0.5s ease-out;
}

.bg-mstars {
	top: -120vh;
	left: -80%;
	width: 25vw;
	height: 17vw;
	background-image: url(../../Assets/img/bg-stars-m.png);
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.6;
}


/* buttons */
.btn {
	padding: 0.9em 2em;
	background: rgba(255, 255, 255, 0.4);
	border-radius: 2em;
	-webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
	margin: 0 1em;
	font-weight: 400;
	transition: all 0.5s ease-out;
}

.btn:hover {
	box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.7);
	color: inherit;
}

.btn.btn-white {
	color: white;
	background: rgba(255, 255, 255, 0.4);
}

.btn.btn-gradient {
	background: rgb(82, 154, 212);
	background: -moz-linear-gradient(198deg, rgba(82, 154, 212, 1) 0%, rgba(46, 200, 212, 1) 100%);
	background: -webkit-linear-gradient(198deg, rgba(82, 154, 212, 1) 0%, rgba(46, 200, 212, 1) 100%);
	background: linear-gradient(198deg, rgba(82, 154, 212, 1) 0%, rgba(46, 200, 212, 1) 100%);
}


.additional-services-btn {
	background: rgba(255, 255, 255, 0.4);
	color: white;
	border: 0;
	border-radius: 2em;
	margin: 0 0.5em;
	font-weight: 400;
	transition: all 0.5s ease-out;
	cursor: pointer;
	width: 15em;
	height: 2.5em;
}

.additional-services-btn:hover {
	box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.7);
	color: #00082f;
}


.blue {
	color: white;
}

.white {
	color: #00082f;
}


h2.title {
	position: relative;
	display: inline-block;
	color: #00082f;
	font-size: 4em;
	font-weight: 900;
	line-height: 1em;
	margin-bottom: 0.4em;
}

h2.title>span {
	color: #f6f6f9;
	/* #ededef */
	display: block;
	font-size: 3em;
	font-weight: 900;
	left: -2.9em;
	margin-top: 6em;
	position: absolute;
	top: -6.15em;
	z-index: -1;
	white-space: nowrap;
}

.blue h2.title {
	color: white;
}

.blue h2.title>span {
	color: rgba(255, 255, 255, .15);
}

.text-shadow {
	text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.9);
}


.v-flex {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: nowrap;
	padding-top: 4em;
}


.w-1-1 {
	width: 100%;
}

.w-1-2 {
	width: 50%;
}

.w-1-3 {
	width: 33%;
}

.w-2-3 {
	width: 66%;
}

.w-1-4 {
	width: 25%;
}

.w-3-4 {
	width: 75%;
}

.text-shadow {
	text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.9);
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}


.v-flex-col {
	display: flex;
	flex-flow: wrap;
	height: 60%;
	margin: 4rem 0;
}

.otv-cart {
	position: relative;
	width: 45%;
	/*min-height: 20%; */
	padding-bottom: 33%;
	margin: 0.5em 0.5em 0.5em 0;
	border-radius: 1.5em;
	background: rgba(255, 255, 255, 0.5);
	text-align: center;
	-webkit-box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.5);
	color: black;
	font-size: clamp(1rem, 1.5vw, 1.2rem);
}

.otv-cart>div {
	position: absolute;
	top: 25%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, 0);
}

.otv-cart big {
	display: block;
	font-weight: 800;
	font-size: 2.3em;
	color: #529AD4;
}

.otv-social img {
	height: 3rem;
}

.otv-about {
	padding-top: 4.3rem;
}

@media only screen and (max-width: 900px) {

	.w-1-2,
	.w-1-3 {
		width: 100%;
	}

	.v-flex {
		flex-direction: column;
		align-items: flex-start;
	}

	.v-flex-col {
		flex-flow: column;
		height: unset;
		padding-top: 6rem;
	}

	.v-flex-col .otv-cart {
		width: 80%;
		padding-bottom: 30%;
		font-size: 3vw;
		margin: 0.5em auto;
	}

	.v-flex-col .otv-cart big {
		margin-top: 0;
	}

	.otv-about {
		order: -1;
	}
}




/* sections */
.s-1 {
	min-height: 40vw;
}

.s-article-nav {
	height: 15em;
}

.s-1-hands {
	position: relative;
	width: 100%;
}

.s-1-hands:after {
	content: " ";
	position: absolute;
	z-index: 400;
	top: -14.5vw;
	width: 100%;
	padding-bottom: 30%;
	background-image: url(../../Assets/img/hands-m.png);
	background-size: 104%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.s-1 .logo {
	width: 45%;
	position: absolute;
	top: 40%;
	left: 5%;
	transform: translate(0, -50%);
}

.s-1 .buttons {
	position: absolute;
	top: 40%;
	right: 10%;
	transform: translate(0, -50%);
	font-size: clamp(0.8rem, 1.3vw, 1.3rem);
}

.s-1 .buttons .btn {
	display: block;
	padding: 1em 1.8em;
	margin: 1.5em 0;
	position: relative;
	color: white;
}

.s-1 .buttons .btn:hover {
	box-shadow: 3px 3px 7px 0 #000000b3;
	color: inherit;
}

.s-1 .buttons .btn-welcome-1:after {
	content: " ";
	position: absolute;
	right: 101%;
	top: 1.3em;
	background-image: url(../../Assets/img/line-1.png);
	background-repeat: no-repeat;
	background-position: right top;
	background-size: contain;
	width: 10em;
	height: 9em;
}

.s-1 .buttons .btn-welcome-2:after {
	content: " ";
	position: absolute;
	right: 101%;
	top: 1.3em;
	background-image: url(../../Assets/img/line-2.png);
	background-repeat: no-repeat;
	background-position: right top;
	background-size: contain;
	width: 7em;
	height: 9em;
}

.s-1 .buttons .btn-welcome-3:after {
	content: " ";
	position: absolute;
	right: 101%;
	top: 1.3em;
	background-image: url(../../Assets/img/line-3.png);
	background-repeat: no-repeat;
	background-position: right top;
	background-size: contain;
	width: 3.8em;
	height: 10.5em;
}


.s-otv {
	margin-top: 5rem;
	min-height: 30rem;
}

.s-11 {
	margin-top: 6em;
}



.s-2 {
	min-height: 30em;
	transition: all 0.5s ease-out;
	background: white;
}

.s-2 .news {
	padding-top: 22vw;
	margin: 0 -2em;
}

.article22 a {
	color: lightyellow;
	transition: all 0.3s;
	border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.article22 a:hover {
	color: white;
	border-bottom: 1px solid white;
}

.article22 {
	background: unset;
	color: white;
}


.article22 table {
	border-radius: 15px;
	padding: 10px !important;
	border-collapse: unset !important;
	background: #add8e6b3;
	border-width: 0 !important;
}

.article22 table td {
	color: black;
	vertical-align: top;
	margin: 0;
	padding: 5px;
	border: 1px solid #f2f2f286;
	border-bottom-width: 0;
	border-left-width: 0;
	border-top-width: 0;
}


/* .article22 table tr:first-child td {
	border: 0;
} */

.article22 table tr td:last-child {
	border: 0;
}


.s-3 {
	min-height: 30em;
}

.s-3>* {
	padding-top: 5vw;
	padding-bottom: 5vw;
}

.s-3.rates {
	padding-top: 5vw;
	padding-bottom: 5vw;
}

.rates h2.title {
	padding-left: 2.5rem;
}

.rates-additional-btns {
	display: flex;
	align-items: center;
}

section.photo-bottom {
	padding-bottom: 40%;
	background-image: url(../../Assets/img/otv/team.jpg);
	background-repeat: no-repeat;
	background-position: bottom -20% center;
	background-size: contain;
}

section.photo-bottom:before {
	display: none !important;
}


.ant-carousel {
	margin: 0 -2rem;
}


.segmented-container {
	display: flex;
	justify-content: space-between;
}


.s-4 {
	min-height: 30em;
	background: white;
	overflow: hidden;
}

.s-4:after,
.s-4:before {
	content: " ";
	position: absolute;
	z-index: -1;
	bottom: 0;
	left: 0;
	width: 50%;
	padding-bottom: 50%;
	transform: translate(-50%, 50%);
	background-image: url(../../Assets/img/circles.png);
	background-position: top left;
	background-repeat: no-repeat;
	background-size: contain;
}

.s-4:before {
	left: unset;
	right: 0;
	transform: translate(50%, 50%);
}

.s-4>* {
	padding: 5vw 0;
}

.s-4 .title {
	width: 100%;
	text-align: center;
}

.s-4 .title>span {
	left: 50%;
	top: -6.25em;
	transform: translate(-50%);
}

.info-links {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	padding: 3em 0 3em;
}

.info-links>div {
	width: 20%;
	margin: 1vw 2%;
}

.info-links a {
	display: block;
	padding-top: 110%;
	color: black;
	text-align: center;
	font-size: 1.5em;
	background-size: contain;
	background-repeat: no-repeat;
}

.info-links a:hover {}


.s-5 {
	min-height: 3em;
}


.banners {
	position: relative;
	width: 100%;
	padding-bottom: 32%;
}

.banners .banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}







.news {
	background-image: url(../../Assets/img/Girl.png);
	background-size: 30%;
	background-repeat: no-repeat;
	background-position: left bottom;
	padding-right: 1.5em;
	padding-bottom: 5em;
	padding-left: 43%;
	text-align: right;
}

.news.news-internet,
.news.news-tv,
.news.news-cctv {
	padding-top: 5vw;
}

.news hr {
	border: 0;
	border-bottom: 1px solid #ededef;
	margin: 2em 0;
}

.news_block {
	text-align: left;
	color: black;

}

.news_block>.head {
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(3px);
	border: 1px solid #ededef;
	border-radius: 1.5em;
	box-shadow: 5px 5px 7px 0 #7a7a7a;
	padding: 1.5em 1em;
	position: relative;
	transition: all 0.3s ease-out;
}

.news_block>.head>h3 {
	float: right;
	font-size: 1.2em;
	margin: 0;
}

.news_block>.head>.left {
	display: inline-block;
}

.news_block .head .date {
	border-bottom: 1px solid #d3d3d3;
	font-size: 1.2em;
	font-weight: 700;
}

.news_block .head .tags {
	display: inline-block;
	font-size: .8em;
}

.news_block .text {
	padding: 0.5em 2em 2em;
}

a.news_block:hover {
	color: black;
}

a.news_block:hover>.head {
	background: rgba(223, 247, 249, 0.3);
	box-shadow: 4px 4px 5px 0 #7a7a7a;
}


.rubrics-news {
	padding: 5em .5em 1em .5rem;
	text-align: left;
}

.rubrics-news h2.title>span {
	left: -9rem;
}

.rubrics-news hr {
	border: 0;
	border-bottom: 1px solid #ededef;
	margin: 2em 0;
}

.rubrics-news_block {
	text-align: left;
	color: black;

}

.rubrics-news_block>.head {
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(3px);
	border: 1px solid #ededef;
	border-radius: 1.5em;
	box-shadow: 5px 5px 7px 0 #7a7a7a;
	padding: 1.5em 1em;
	position: relative;
	margin: 1rem;
	transition: all 0.3s ease-out;
	/* display: flex; */
	align-items: center;
}

.rubrics-news_block>.head>h3 {
	font-size: 1.2em;
	margin: 0;
	margin-right: 5px;
	flex-shrink: 0;
}

.rubrics-news_block>.head>.text {
	padding-top: 3px;
	flex-grow: 1;
}


a.rubrics-news_block:hover {
	color: black;
}

a.rubrics-news_block:hover>.head {
	background: rgba(223, 247, 249, 0.3);
	box-shadow: 4px 4px 5px 0 #7a7a7a;
}

@media (max-width: 600px) {
	.rubrics-news_block>.head {
		flex-direction: column;
		align-items: flex-start;
	}

	.rubrics-news_block>.head>h3 {
		margin-right: 0;
		margin-bottom: 10px;
	}
}


.article {
	font-weight: 300;
	width: 100%;
	font-size: 1.1em;
}


.article .title>span {
	left: 50%;
	transform: translate(-45%);
	white-space: nowrap;
}

.article h3 {
	font-size: 2em;
	font-weight: 600;
	margin-top: 0.2em;
	margin-bottom: 0;
	text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.6);
}

.article h4 {
	font-size: 2em;
	font-weight: 300;
	margin-top: 0.2em;
	margin-bottom: 0;
}

.article p {
	margin-top: 0.2em;
	margin-bottom: 1.4em;
}





.s-footer {
	position: relative;
	/* min-height: 5em; */
}

/* .s-footer:after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-bottom: 100%;
} */



.footer-bg {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: 120%;
	background-position: bottom center;
	z-index: 1;
	overflow: hidden;
}

.footer-bg.f-circle {
	background-image: url(../../Assets/img/f-circle.png);
}

.footer-bg.f-clouds {
	background-image: url(../../Assets/img/f-clouds.png);
}

.footer-bg.f-mountains {
	background-image: url(../../Assets/img/podval3.png)
}

.footer-bg.f-stars {
	background-image: url(../../Assets/img/stars-footer.png)
}



@media only screen and (max-width: 900px) {
	/* .bg-img {
		height: 150vh;
	} */

	.bg-clouds {
		background-size: 100% 40%;
	}

	.bg-mstars {
		top: -140vh;
	}


	h2.title {
		font-size: 8vw;
	}

	.main section.s-1 {
		min-height: 45vw;
	}

	.s-2 .news {
		padding-top: 20vw;
	}

	.news {
		background-image: url(../../Assets/img/circles.jpg);
		background-size: 50%;
		background-position: bottom -10%;
		padding-left: 1.5em;
	}

	.s-3 .title {
		width: 100%;
	}

	.s-4>* {
		padding: 10vw 0;
	}

	.s-4:after {
		content: " ";
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 150%;
		padding-bottom: 150%;
		transform: translate(-50%, 50%);
		background-image: url(../../Assets/img/circles.png);
		background-position: top left;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.s-4:before {
		display: none;
	}

	.segmented-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: space-between;
	}

	.rates-additional-btns {
		flex-direction: column;
		gap: inherit;
		align-items: flex-end;
	}

	.info-links>div {
		width: 30%;
		margin: 3vw 2%;
	}

	.info-links a {
		font-size: 3vw;
	}

	.v-flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: nowrap;
	}

	.w-1-2 {
		width: 100%;
	}

	.w-1-3 {
		width: 100%;
	}

}


@media only screen and (max-width: 600px) {
	.bg-img {
		height: 200vh;
	}

	.bg-clouds {
		background-size: 100% 40%;
	}

	.bg-mstars {
		top: -150vh;
		left: -30%;
		width: 40vw;
	}

	.main>nav ul .hambugger {
		display: block;
	}

	.main>nav ul {
		list-style: none;
		display: block;
		float: right;
		margin-right: 2em;
		overflow: hidden;
		border: 1px solid white;
		height: 5em;
	}

	.main>nav ul:hover {
		height: 18em;
	}

	.main section.s-1 {
		min-height: 60vh;
	}

	.s-1 .logo {
		display: none;
	}

	.s-1 .buttons {
		position: absolute;
		top: 45%;
		right: 15%;
		transform: translate(0, -50%);
		font-size: 1em;
	}

	.s-3.rates {
		padding-top: 6em;
	}


	.rates-additional-btns {
		flex-direction: column;
		gap: inherit;
		align-items: flex-end;
	}

	.s-3.services {
		padding-top: 4em;
	}

	.segmented-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: space-between;
	}

	.article h3,
	.article h4 {
		font-size: 1.4em;
		margin-bottom: 1em;
		margin-top: 0.2em;
		line-height: 1em;
	}

	.article p {
		font-size: 0.9em;
		line-height: 1.3em;
	}

	.info-links a {
		font-size: 4vw;
	}

	.header {
		width: 90%;
		margin: 0 auto
	}

	.flex {
		width: 90%;
		margin: 0 auto
	}

	.additional-services-btn {
		height: 2rem;
		margin-left: 0;
	}


}