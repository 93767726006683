.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.menu-item {
    color: #fff;
    margin: 1.5em
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.menu-item:hover {
    color: lightblue;
    margin: 1.5em
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
}

.menu-item {
    position: relative;
}

.menu-item::after {
    content: '';
    position: absolute;
    top: 69px;
    left: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s, bottom 0.3s;
}

.menu-item:hover::after {
    background-color: #fff;
}

.menu-item.ant-menu-item-selected::after {
    background-color: #fff;
}


li.ant-menu-item.menu-item {
    margin: 3em 1.5em 0.8em !important;
}

li.ant-menu-item.menu-item:after {
    top: 3.6em !important;
}

.ant-list-split .ant-list-item {
    border-block-end: none
}


.ant-layout-footer.footer {
    background-color: rgb(2 10 59 / 0%);
    position: absolute;
    width: 100%;
    height: 3em;
    z-index: 990;
}


.ant-segmented.rates-segemented.blue {
    display: block;
    margin: 2em 0;
    background: rgba(255, 255, 255, .15);
    margin-left: 3.5em;
    border-radius: 2em;
}

.ant-segmented.rates-segemented.blue .ant-segmented-item-selected {
    border-radius: 2em;
    color: white;
    padding: 0.1em 0.3em;
    background: rgba(255, 255, 255, 0.5);
}


.ant-segmented.rates-segemented.blue .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    border-radius: 2em;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    color: white;
}

.ant-segmented.rates-segemented.blue .ant-segmented-thumb {
    font-weight: 300;
    font-size: 1.3em;
    /* padding: 0.4em 0.7em; */
    background: rgba(219, 211, 211, 0.15);
}

.ant-segmented .ant-segmented-item-label {
    padding: 0 9.2px;
}

@media only screen and (max-width: 600px) {

    .hambugger {
        display: none;
    }

    li.ant-menu-item.menu-item {
        margin: 0 !important;
    }

    .ant-segmented.rates-segemented.blue {
        margin: 0.5em 0
    }

}

@media only screen and (max-width: 900px) {

    .bg-img {
        height: 150vh;
    }

    .bg-clouds {
        background-size: 100% 40%;
    }

    .bg-mstars {
        top: -140vh;
    }

    .main section.s-1 {
        min-height: 50vh;
    }

    .ant-segmented.rates-segemented.blue {
        width: 18.9em;
        margin-left: 0;
    }

    .ant-segmented.rates-segemented.blue .ant-segmented-item {
        font-size: 12px;
    }


}

@media (min-width: 1100px) {

    .menu-icon {
        display: none;
        /* Скрываем кнопку на больших экранах */
    }

    .hambugger {
        display: none;
        /* Скрываем кнопку на больших экранах */
    }

    .mobile-drawer {
        display: none;
        /* Скрываем Drawer на больших экранах */
    }
}

@media (max-width: 1100px) {

    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.menu-item {
        margin: 5px
    }

    .hambugger {
        display: none !important;

    }

}