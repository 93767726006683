body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.demo-logo-vertical {
  height: 32px;
  margin: 24px;
  background: linear-gradient(135deg, #f17432 8%, #f17432 14%, #ea5507 28%, #ea5507 28%, #d36c27 55%, #5e5012 57%, #384246 63%, #07090a 71%, #626d72 99%, #fb955e 100%);
  /* background: orange; */
  border-radius: 6px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: 50px;
  left: 10px;
  z-index: 2;
  color: gray;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: 50px;
  right: 10px;
  z-index: 2;
  color: gray;
}

.ant-carousel .slick-dots li {
  font-size: 20px;
  width: 3px;
}