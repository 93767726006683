/* html, body {
	margin: 0; padding: 0; height: 100vh;
	background: black;
	overflow: hidden !important;

	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;

} */


@media (max-width: 768px) {

    .ant-typography.admin-text {
        font-size: 14px !important;
    }

    .ant-typography.user-profile-text {
        font-size: 12px !important;
    }

    .ant-avatar.ant-avatar-circle.user-avatar {
        width: 20px;
        height: 20px;
    }

}

@media (min-width: 768px) {

    .admin-menu-icon {
        display: none;
    }

}


.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 170px;
    margin-inline-end: 4px;
   
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
}