 /* Карусель на главной */

 .carousel {
 	/* display: flex; */
 }

 .ant-carousel .slick-track {
 	display: flex;
 }

 .carousel:before {
 	content: " ";
 	position: absolute;
 	bottom: -6em;
 	right: 0;
 	width: 50%;
 	height: 100%;
 	background-image: url(../../Assets/img/serv/big-icons.png);
 	background-repeat: no-repeat;
 	background-size: contain;
 }

 /* Шириной управлял здесь */
 /* .carousel .slick-slide {
 	width: 10%;
 } */

 /* и тут  */
 /* .slick-list {
 	width: 100%;
 } */


 .carousel .c-card {
 	margin: 1em 5% 1em 15%;
 	min-width: 16em;
 	padding-bottom: 3em;
 	width: auto !important;
 }

 .carousel .c-card .bg {
 	width: 90%;
 	padding-bottom: 15%;
 	overflow: hidden;
 	position: relative;
 	background-color: rgba(255, 255, 255, 0.3);
 	text-align: center;
 	color: white;
 	border-radius: 2em;
 	box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.3);
 }

 .carousel .c-card .bg .img {
 	padding-bottom: 85%;
 	background-color: rgba(255, 255, 255, 0.3);
 	background-size: 50%;
 	background-position: bottom center;
 	background-repeat: no-repeat;
 	margin-bottom: 1em;
 }

 .carousel .c-card hr {
 	border: 0;
 	border-bottom: 1px solid white;
 	width: 70%;
 	margin: 0 auto;
 }

 .carousel .c-card .text {
 	font-weight: 400;
 	line-height: 1.1em;
 	margin: 1em 1em 2.5em;
 	min-height: 3.5em;
 	padding: 0.5em;
 }

 .carousel .c-card .title {
 	font-size: clamp(1rem, 1.8vw, 1.8rem);
 	font-weight: 600;
 	min-height: 3.5em;
 	padding: 0.5em;
 	width: unset;
 }


 /* Карусель тарифных планов */
 .ant-carousel .carousel-rates:before {
 	display: none;
 }

 .ant-carousel .carousel-rates .c-card {
 	margin: 1em auto;
 	min-width: 16em;
 	padding-bottom: 3em;
 	width: 90% !important;
 }


 .ant-carousel .carousel-rates .c-card .bg {
 	/* width: 90%; */
 	padding-bottom: 15%;
 	overflow: hidden;
 	position: relative;
 	background-color: rgba(255, 255, 255, 0.3);
 	text-align: center;
 	color: white;
 	border-radius: 2em;
 	box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.3);
 }

 .ant-carousel .carousel-rates .c-card .bg .img {
 	padding-bottom: 100%;
 	background-color: rgba(255, 255, 255, 0.3);
 	background-size: 50%;
 	background-position: bottom center;
 	background-repeat: no-repeat;
 	margin-bottom: 1em;
 }


 .ant-carousel .carousel-rates .c-card .text {
 	font-size: 1.3em;
 	margin: 0 .5em 1.5em;
 	min-height: 0;
 	min-height: auto;
 }

 .ant-carousel .carousel-rates .c-card .title {
 	/* margin-bottom: 1em; */
 	min-height: 2em;
 	position: absolute;
 	top: 0.5em;
 	font-size: 2.2em;
 	font-weight: 600;
 	left: 50%;
 	transform: translate(-50%);
 	white-space: normal;
 	width: 90%;
 	line-height: 1em;
 	margin: 0.3em 0;
 }


 .ant-carousel .carousel-rates .c-card .text em {
 	font-style: normal;
 	font-size: 1.2em;
 	font-weight: 600;
 }

 .ant-carousel .carousel-rates .c-card .text big {
 	font-size: 1.8em;
 	font-weight: 700;
 	text-shadow: 5px 5px 7px #000000e6;
 }

 .ant-carousel .carousel-rates .c-card hr {
 	border: 0;
 	border-bottom: 1px solid #fff;
 	margin: 1em auto;
 	width: 70%;
 }

 .ant-carousel .carousel-rates .c-card .price {
 	font-size: 2.5em;
 	font-weight: 600;

 }

 .ant-carousel .carousel-rates .c-card .bg .img {
 	background-color: unset;
 	background-size: cover;
 	padding-bottom: 110%;
 }


 .ant-carousel .slick-next {
 	top: 45%;
 }

 .ant-carousel .slick-prev {
 	top: 45%;
 }


 .ant-carousel .slick-prev,
 .ant-carousel .slick-prev:hover,
 .ant-carousel .slick-prev:focus {
 	font-size: 50px;
 	color: white;
 	left: -0.2em;
 	opacity: 0.6;
 }

 .ant-carousel .slick-prev::after {
 	display: none;
 }

 .ant-carousel .slick-next::after {
 	display: none;
 }

 .ant-carousel .slick-next,
 .ant-carousel .slick-next:hover,
 .ant-carousel .slick-next:focus {
 	font-size: 50px;
 	right: 0.5em;
 	color: white;
 	opacity: 0.6;
 }


 @media only screen and (max-width: 800px) {
 	.carousel .c-card .title {
 		font-size: 3vw !important;
 	}
 }

 @media only screen and (max-width: 550px) {
 	.carousel .c-card .title {
 		font-size: 6vw !important;
 	}
 }